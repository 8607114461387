import React from "react"
import { Link } from "gatsby"
import EU from "../../../images/footer/icons/EU.svg"
import USA from "../../../images/footer/icons/USA.svg"
import UA from "../../../images/footer/icons/UA.svg"
import Skype from "../../../images/footer/icons/Skype.svg"
import Email from "../../../images/footer/icons/Email.svg"
import Linkedin from "../../../images/footer/icons/Linkedin.svg"
import Facebook from "../../../images/footer/icons/Facebook.svg"
import Instagram from "../../../images/footer/icons/Instagram.svg"
import Twitter from "../../../images/footer/icons/Twitter.svg"
import Behance from "../../../images/footer/icons/Behance.svg"
import DownArrow from "../../../images/footer/icons/DownArrow.svg"
import "./Footer.scss"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openOne: false,
      openTwo: false,
      openThree: false,
      openFour: false,
      openFive: false,
      width: 0,
      height: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.mobileWidth = 740
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  toggleOpenOne = () => {
    this.setState({
      openOne: !this.state.openOne,
    })
  }
  toggleOpenTwo = () => {
    this.setState({
      openTwo: !this.state.openTwo,
    })
  }
  toggleOpenFour = () => {
    this.setState({
      openFour: !this.state.openFour,
    })
  }
  toggleOpenFive = () => {
    this.setState({
      openFive: !this.state.openFive,
    })
  }

  render() {
    const { openOne, openTwo, openFour, openFive, width } = this.state
    return (
      <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-center">
            <div className="footer-info">
              <div className="footer-block">
                <div className="footer-title" onClick={this.toggleOpenOne}>
                  Services
                  <img
                    src={DownArrow}
                    alt="Arrow"
                    className={openOne ? "up arrow" : "down arrow"}
                  />
                  <div className="blue-line line1"></div>
                </div>
                <div
                  className="footer-list mobile"
                  style={
                    openOne || width > this.mobileWidth
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Link to="/services/mobile/">Mobile App Development</Link>
                  <Link to="/services/web/">Web App Development</Link>
                  <Link to="/services/dedicatedteam/">Development Team</Link>
                  <Link to="/expertise/cloud-solutions/">
                    DevOps &amp; Cloud services
                  </Link>
                  <Link to="/services/qa/">Software QA &amp; Testing</Link>
                  <a
                    href="https://startup.os-system.com/"
                    target="_blank"
                    className="footer-text"
                  >
                    Startup Consulting
                  </a>
                  <Link to="/expertise/uiuxdesign/">UI/UX Design</Link>
                </div>
              </div>
              <div className="footer-block">
                <div className="footer-title" onClick={this.toggleOpenTwo}>
                  EXPERTISE
                  <img
                    src={DownArrow}
                    alt="Arrow"
                    className={openTwo ? "up arrow" : "down arrow"}
                  />
                  <div className="blue-line line2"></div>
                </div>

                <div
                  className="footer-list"
                  style={
                    openTwo || width > this.mobileWidth
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Link to="/expertise/medical-app-development-services/">
                    Medical App Development
                  </Link>
                  <Link
                    to="https://flutter.os-system.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Flutter Mobile App Development
                  </Link>
                  <Link
                    to="https://iot.os-system.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Internet of Things Solutions
                  </Link>
                  <Link
                    to="https://aws.os-system.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AWS Cloud Solutions
                  </Link>
                  <Link to="/expertise/cloud-solutions/">Cloud Solutions</Link>
                  <Link to="/expertise/rtc/">Real Time Communication</Link>
                  <Link to="/expertise/uiuxdesign/">UI/UX</Link>
                </div>
              </div>
              <div className="footer-block">
                <div className="footer-title" onClick={this.toggleOpenFour}>
                  COMPANY
                  <img
                    src={DownArrow}
                    alt="Arrow"
                    className={openFour ? "up arrow" : "down arrow"}
                  />
                  <div className="blue-line line4"></div>
                </div>

                <div
                  className="footer-list"
                  style={
                    openFour || width > this.mobileWidth
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Link to="/how-we-work/">How We Work</Link>
                  <Link to="/company/">About Us</Link>
                  <a href="/blog/">Our Blog</a>
                  <a href="https://ossystem.ua/vacancies" target="_blank">
                    Careers
                  </a>
                </div>
              </div>
              <div className="footer-block">
                <div className="footer-title" onClick={this.toggleOpenFive}>
                  CONTACTS
                  <img
                    src={DownArrow}
                    alt="Arrow"
                    className={openFive ? "up arrow" : "down arrow"}
                  />
                  <div className="blue-line line5"></div>
                </div>

                <div
                  className="footer-list"
                  style={
                    openFive || width > this.mobileWidth
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="footer-list-item">
                    <img className="footer-icon" src={USA} alt="USA" />
                    <a href="tel:+16177786970" className="footer-text">
                      +1 617 778 69 70
                    </a>
                  </div>
                  <div className="footer-list-item">
                    <img className="footer-icon" src={UA} alt="UA" />
                    <a href="tel:+380952794212" className="footer-text">
                      +38 095 279 42 12
                    </a>
                  </div>
                  <div className="footer-list-item">
                    <img className="footer-icon" src={Skype} alt="Skype" />
                    <a href="skype:oss.ua?call" className="footer-text">
                      oss.ua
                    </a>
                  </div>
                  <div className="footer-list-item">
                    <img className="footer-icon" src={Email} alt="Email" />
                    <a
                      href="mailto:office@os-system.com"
                      className="footer-text"
                    >
                      office@os-system.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container underscore">
          <div className="footer-social">
            <div className="footer-links">
              <a
                className="footer-link"
                href="https://www.linkedin.com/company/3552182"
                target="_blank"
              >
                <img className="link-img" src={Linkedin} alt="Linkedin" />
              </a>
              <a
                className="footer-link"
                href="https://www.facebook.com/ossystem.ltd/"
                target="_blank"
              >
                <img className="link-img" src={Facebook} alt="Facebook" />
              </a>
              <a
                className="footer-link"
                href="https://www.instagram.com/ossystem_company/"
                target="_blank"
              >
                <img className="link-img" src={Instagram} alt="Instagram" />
              </a>
              <a
                className="footer-link"
                href="https://twitter.com/ossystem_com"
                target="_blank"
              >
                <img className="link-img" src={Twitter} alt="Twitter" />
              </a>
              <a
                className="footer-link"
                href="https://www.behance.net/OSSystem"
                target="_blank"
              >
                <img className="link-img" src={Behance} alt="Bēhance" />
              </a>
            </div>
            <div className="footer-copyright">
              © OS-system, {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
