import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';
import FloatingForm from '../FloatingForm';
import CookieBanner from '../CookieBanner';
import SnackbarContext from "../../../contexts/SnackbarContext";

import './Layout.scss';
import SnackbarComponent from '../SnackbarComponent';

export default function Layout({ children, showFormPoint }) {
  const [showFloatingForm, setShowFloatingForm] = useState(false);

  // Snackbar section----------> start
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});

  const handleOpenSnackbar = (snackbarParams) => {
    if (snackbarParams) setSnackbarData(snackbarParams)
    setOpenSnackbar(true);
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const snackbarContextData = {
    handleOpenSnackbar: handleOpenSnackbar,

  };
  // Snackbar section----------> end

  useEffect(() => {
    if (localStorage.getItem('allowCookie') === 'true') {
      setShowFloatingForm(true)
    }
  }, [])
  return (
    <div className='site-container'>
      <SnackbarContext.Provider value={snackbarContextData}>
        <Header />
        <CookieBanner enableForm={setShowFloatingForm} />
        {/* {showFloatingForm && <FloatingForm showFormPoint={showFormPoint} />} */}
        <main id='main'>{children}</main>
        <Footer />
        <SnackbarComponent
          open={openSnackbar}
          closeSnackbar={closeSnackbar}
          {...snackbarData}
        />
      </SnackbarContext.Provider>
    </div>
  )
}
