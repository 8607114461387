import React from 'react';
import { sendEmail } from '../../../js/helper';


import InputMask from 'react-input-mask';

import './FloatingForm.scss';

export default class FlowForm extends React.Component {
  static reAppearTimeout;
  static emptyTimeout;

  constructor(props) {
    super(props)
    this.state = {
      number: '',
      error: '',
      isSent: false,
      isClosed: false,
      isWaiting: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScroll);

    if (localStorage.getItem('allowCookie')) {
      this.setState({
        isCookies: true
      })
    }
  }

  updateScroll = () => {
    let currentScroll = window.pageYOffset;
    let pointToShowForm = this.props.showFormPoint;

    if (currentScroll >= pointToShowForm) {
      this.setState({
        isScrolledTo: true,
      })
    } else {
      this.setState({
        isScrolledTo: false,
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      number: event.target.value,
      error: '',
      isSent: false
    })
  }

  checkNumber = () => {
    const { number } = this.state;

    if (number.trim().length >= 13) {
      return true;
    } else {
      return false;
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { number } = this.state;

    if (this.checkNumber()) {
      this.setState({isWaiting: true});
      await sendEmail({
        phone: number,
      })
      this.setState({
        error: '',
        isWaiting: false,
        isSent: true
      })
      this.emptyTimeout = setTimeout(() => {
        this.setState({
          number: '',
          isSent: false,
        })
      }, 30000)
    } else {
      this.setState({
        error: 'error'
      })
    }
  }

  closeForm = () => {
    this.setState({
      isClosed: true
    })
    this.reAppearTimeout = setTimeout(() => {
      this.setState({
        isClosed: false,
      })
    }, 60000)
  }

  componentWillUnmount() {
    clearTimeout(this.reAppearTimeout);
    clearTimeout(this.emptyTimeout);
    window.removeEventListener('scroll', this.updateScroll);
  }

  render() {
    const { isSent, error, number, isClosed, isScrolledTo, isWaiting } = this.state;

    if (isClosed || !isScrolledTo) {
      return null;
    }

    return (
      <div className={isSent ? 'flow-form-container isSent' : 'flow-form-container'} >
        <div className='flow-form-wrapper'>
          <span
            className='icon-cross'
            onClick={() => this.closeForm()}
          />
          <span className='form-title'>{isSent ? "We'll call you back soon" : 'Still have questions?'}</span>

          <form id='flow-form' onSubmit={this.handleSubmit}>
            <div className='flow-form-inner-container'>
              <div className='flow-input-container'>
                <InputMask
                  className='flow-number-input'
                  alwaysShowMask
                  mask='+ 999999999999999999999'
                  maskChar={null}
                  onChange={this.handleChange}
                  value={this.state.number}
                  disabled={isWaiting}
                />
                {error && <span className='flow-error-message'>Enter phone number</span>}
                {!error && <span className='flow-form-tooltip'>*Enter phone number with country code</span>}
              </div>
              <button type='submit' className='flow-form-btn'>{isWaiting ? 'Sending...' : 'Call me back!'}</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
};


