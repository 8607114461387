import React from 'react';
import { Link } from 'gatsby';
// import { mainUrl } from '../../../js/config';
import dataForHeader from '../../../information/dataForHeader.json';
import logo from '../../../images/header/logo.svg';
import logo_blue from '../../../images/header/logo_blue.svg';

import './Header.scss';
export default class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      isExpanded: false,
    }
  }

  componentDidUpdate() {
    const { isExpanded } = this.state;
    let body = document.querySelector('body');
    if (isExpanded) {
      body.setAttribute('style', 'overflow: hidden');
    } else {
      body.removeAttribute('style');
    }
  }

  toggleShow = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

  render() {
    const { isExpanded } = this.state;
    return (
      <div
        className={isExpanded ? 'header-container active' : 'header-container'}
      >
        <div className={isExpanded ? 'header-center active' : 'header-center'}>
          <Link
            to='/'
            className={
              isExpanded
                ? 'header-logo-container active'
                : 'header-logo-container'
            }
          >
            {!isExpanded ?
            <img src={logo_blue} alt='OS-system logo' className='header-logo' />
              :
            <img src={logo} alt='OS-system logo' className='header-logo' />}
          </Link>
          <div className={isExpanded ? 'header-menu active' : 'header-menu'}>
            <div className='header-list'>

              {dataForHeader.map(item => {
                const { id, href, itemClass, content } = item
                return  content === 'Blog' ? (
                    <a key={id} href={href} className={itemClass}>
                      {content}
                    </a>
                ) : (
                    <Link
                    key={id}
                    to={href}
                    className={itemClass}
                    activeClassName='selected'
                    partiallyActive={!(href === '/') && true}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {content}
                  </Link>
                )
              })}

            </div>
            <Link
              offset='0'
              to='/contact/'
              className='header-contact-button-container'
              onClick={() => window.scrollTo(0, 0)}
              >
              <div className='header-contact-button'>Contact us</div>
            </Link>
            <div
              className={
                isExpanded ? 'header-menu-icon active' : 'header-menu-icon'
              }
              onClick={this.toggleShow}
            >
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
