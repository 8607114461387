import crypto from "crypto"
import axios from "axios"

const endpointUrl = () => {
  return process.env.GATSBY_API_URL || "https://edelivery-dev.os-system.com"
}

const authHeader = () => {
  const d = new Date()
  const hash = crypto
    .createHash("md5")
    .update(d.toISOString().split("T")[0])
    .digest("hex")
  return {
    "X-Authorization": hash,
  }
}

export async function sendEmail(
  data,
  file,
  formId,
  context,
  setLetterSent,
  setSentIsError
) {
  if (setSentIsError) {
    setSentIsError(false)
  }
  let result = false
  if (!data) {
    alert("No data")
    return false;
  }
  data.site = "com"
  let formData = new FormData()
  for (let key in data) {
    formData.append(key, data[key])
  }
  if (file && typeof file != "undefined") {
    formData.append("file", file.files[0])
    try {
      formData.append('filename', file.files[0].name)
      formData.append('size', file.files[0].size)
      const data = await (await fetch(`${endpointUrl()}/api/get_signed_url`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ filename: file.files[0].name, size: file.files[0].size }),
      })).json()
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', file.files[0].type)
      await fetch(`${data.clientUrl}`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: myHeaders,
        body: file.files[0],
        referrerPolicy: 'no-referrer',
        redirect: 'follow',
      })
      formData.append('key', data.key)
      formData.delete('file')
    } catch (error) {
      result = false
      console.log(error)
      if (setSentIsError) {
        setSentIsError(true);
        setTimeout(() => {
          setSentIsError(false);
        }, 10000)
      }
    }
  }
  if (formId) {
    formData.append("formId", formId)
  }
  if (context) {
    formData.append("context", JSON.stringify(context))
  }

  try {
    const response = await axios.post(
      `${endpointUrl()}/api/send_with_hs`,
      formData,
      {
        headers: { ...authHeader() },
      },
      { timeout: 5000 }
    )

    result = response && response.data
    if (setLetterSent) {
      setLetterSent(true);
    }
  } catch (error) {
    result = false
    console.log(error)
    if (setSentIsError) {
      setSentIsError(true);
      setTimeout(() => {
        setSentIsError(false);
      }, 10000)
    }
  }
  return result
}

export function scrollToInvalidField(
  inputValues,
  errorValues,
  idGo = "#estimate-header"
) {
  let inputMessages = Object.values(inputValues).slice(0, 3)
  let errorMessages = Object.values(errorValues)
  if (inputMessages.includes("") || errorMessages.includes(true)) {
    document.querySelector(idGo).scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }
}
