import React from 'react';
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './SnackbarComponent.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => {
  return ({
    snackbarContainer: {
      width: '100%',
    },
    snackbar: {
      marginTop: theme.spacing(9),
    },
    alert: {
      fontSize: '14px',
    },
  })
})

const SnackbarComponent = ({ open, closeSnackbar, message, autoHideDuration, anchorOrigin, severity }) => {

  const classes = useStyles()


  return (
    <div className={classes.snackbarContainer}>
      <Snackbar
        className={classes.snackbar}
        open={open}
        autoHideDuration={autoHideDuration || null}
        onClose={closeSnackbar}
        anchorOrigin={anchorOrigin}
      >
        <Alert onClose={closeSnackbar} severity={severity} className={classes.alert}>
          {message}

        </Alert>
      </Snackbar>
    </div>
  )
}

export default SnackbarComponent;

SnackbarComponent.defaultProps = {
  open: false,
  message: '',
  autoHideDuration: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  severity: 'success'
}

SnackbarComponent.proptypes = {
  open: PropTypes.bool.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.oneOfType([
    PropTypes.number,
  ]),
  anchorOrigin: PropTypes.object,
  severity: PropTypes.string,
}